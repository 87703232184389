import { useMemo } from 'react'
import BigNumber from 'bignumber.js'

import { useBalance } from 'api/balances-api'

export const useBalanceData = (hideZeroBalances) => {
  const { data: balance, isFetching, refetch } = useBalance()

  const { data, currencies, brokerWallets } = useMemo(() => {
    if (!balance) {
      return {
        data: [],
        currencies: [],
        brokerWallets: {},
      }
    }

    const { cexAssets, dexAssets } = balance

    const balances = {}
    const brokersSet = new Set()
    const brokerWallets = {}
    const totalBalancesByBroker = {
      name: 'TOTAL',
      total: new BigNumber('0'),
    }

    if (cexAssets && cexAssets?.length !== 0) {
      for (const balance of cexAssets) {
        if (hideZeroBalances && balance.balanceUsdt === '0') {
          continue
        }

        brokersSet.add(balance.exchange)

        totalBalancesByBroker.total = totalBalancesByBroker.total.plus(new BigNumber(balance.balanceUsdt || '0'))

        if (!totalBalancesByBroker[balance.exchange]) {
          totalBalancesByBroker[balance.exchange] = {
            balanceUsdtBigNumber: new BigNumber(balance.balanceUsdt || '0'),
          }
        } else {
        // eslint-disable-next-line max-len
          totalBalancesByBroker[balance.exchange].balanceUsdtBigNumber = totalBalancesByBroker[balance.exchange].balanceUsdtBigNumber.plus(new BigNumber(balance.balanceUsdt || '0'))
        }

        if (!balances[balance.name]) {
          balances[balance.name] = {
            name: balance.name,
            [balance.exchange]: balance,
            total: new BigNumber(balance.balanceUsdt || '0'),
          }
        } else {
          balances[balance.name][balance.exchange] = balance
          balances[balance.name].total = balances[balance.name].total.plus(new BigNumber(balance.balanceUsdt || '0'))
        }
      }
    }

    if (dexAssets && dexAssets?.length !== 0) {
      for (const balance of dexAssets) {
        if (hideZeroBalances && balance.balanceUsdt === '0') {
          continue
        }

        brokersSet.add(balance.network)
        brokerWallets[balance.network] = balance.dexWallet

        totalBalancesByBroker.total = totalBalancesByBroker.total.plus(new BigNumber(balance.balanceUsdt || '0'))

        if (!totalBalancesByBroker[balance.network]) {
          totalBalancesByBroker[balance.network] = {
            balanceUsdtBigNumber: new BigNumber(balance.balanceUsdt || '0'),
          }
        } else {
        // eslint-disable-next-line max-len
          totalBalancesByBroker[balance.network].balanceUsdtBigNumber = totalBalancesByBroker[balance.network].balanceUsdtBigNumber.plus(new BigNumber(balance.balanceUsdt || '0'))
        }

        if (!balances[balance.name]) {
          balances[balance.name] = {
            name: balance.name,
            [balance.network]: balance,
            total: new BigNumber(balance.balanceUsdt || '0'),
          }
        } else {
          balances[balance.name][balance.network] = balance
          balances[balance.name].total = balances[balance.name].total.plus(new BigNumber(balance.balanceUsdt || '0'))
        }
      }
    }

    return {
      data: [totalBalancesByBroker, ...Object.values(balances)],
      currencies: [...brokersSet],
      brokerWallets,
    }
  }, [balance, hideZeroBalances])

  return [data, currencies, brokerWallets, isFetching, refetch]
}
