import { flexRender } from '@tanstack/react-table'
import cx from 'classnames'

import s from '../ReservesTable.module.css'

const CELL_CLASSES = {
  Expected: s.expectedCell,
  Factual: s.factualCell,
}

const getCommonPinningStyles = (column) => {
  if (column.id === 'name') {
    return {
      left: `${column.getStart('left')}px`,
      position: 'sticky',
      zIndex: 20,
    }
  }

  return null
}

const TableHeadComponent = ({
  headerGroups,
  virtualPaddingLeft,
  virtualPaddingRight,
  columnVirtualizer,
}) => {
  return (
    <thead style={{
      display: 'grid',
      position: 'sticky',
      top: 0,
      zIndex: 30,
    }}>
      {headerGroups.map((headerGroup) => {
        const virtualColumns = columnVirtualizer.getVirtualItems()

        return (
          <tr key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
            {virtualPaddingLeft ? (
              //fake empty column to the left for virtualization scroll padding
              <th style={{ display: 'flex', width: virtualPaddingLeft }} />
            ) : null}
            {virtualColumns.map((virtualColumn) => {
              const column = headerGroup.headers[virtualColumn.index]
              const sorting = column.column.getIsSorted()

              return (
                <th
                  key={column.id}
                  style={{
                    display: 'flex',
                    width: column.getSize(),
                    ...getCommonPinningStyles(column),
                    zIndex: 30,
                  }}
                  className={column.id === 'name' ? s.nameColumn : ''}
                >
                  <div className={s.tableHeadCell} onClick={column.column.getToggleSortingHandler()}>
                    <span
                      style={{
                        userSelect: 'none',
                      }}
                      id='column_title'
                    >
                      {flexRender(column.column.columnDef.header, column.getContext())}
                    </span>
                    {sorting && (
                      <i
                        style={{
                          fontSize: '11px',
                          justifySelf: 'flex-start',
                        }}
                        className={`fas fa-chevron-${sorting ? (sorting === 'asc' ? 'down' : 'up') : 'up'}`}
                      />
                    )}
                  </div>
                </th>
              )
            })}
            {virtualPaddingRight ? (
              //fake empty column to the right for virtualization scroll padding
              <th style={{ display: 'flex', width: virtualPaddingRight }} />
            ) : null}
          </tr>
        )
      })}
    </thead>
  )
}

const TableBodyComponent = ({
  rows,
  columnVirtualizer,
  virtualPaddingLeft,
  virtualPaddingRight,
}) => {
  return (
    <tbody  style={{
      display: 'grid',
      zIndex: 20,
      position: 'relative', //needed for absolute positioning of rows
    }}>
      {rows?.map((row) => {
        const visibleCells = row.getVisibleCells()
        const virtualColumns = columnVirtualizer.getVirtualItems()

        return (
          <tr
            key={row.id}
            style={{
              display: 'flex',
              // position: 'absolute',
              width: '100%',
            }}
          >
            {virtualPaddingLeft ? (
            //fake empty column to the left for virtualization scroll padding
              <td style={{ display: 'flex', width: virtualPaddingLeft }} />
            ) : null}
            {virtualColumns.map((vc) => {
              const cell = visibleCells[vc.index]

              return (
                <td
                  className={cx(CELL_CLASSES[cell.getValue()] || '', cell.column.id === 'name' && s.nameColumn)}
                  key={cell.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: cell.column.getSize(),
                    ...getCommonPinningStyles(cell.column),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
            {virtualPaddingRight ? (
            //fake empty column to the right for virtualization scroll padding
              <td style={{ display: 'flex', width: virtualPaddingRight }} />
            ) : null}
          </tr>
        )
      })}
    </tbody>
  )
}

export const ReservesTableBody = ({
  virtualPaddingLeft,
  virtualPaddingRight,
  columnVirtualizer,
  tableProps,
}) => {
  return (
    <table className={s.table} style={{ display: 'grid' }}>
      <TableHeadComponent
        headerGroups={tableProps.getHeaderGroups()}
        virtualPaddingLeft={virtualPaddingLeft}
        virtualPaddingRight={virtualPaddingRight}
        columnVirtualizer={columnVirtualizer}
      />
      <TableBodyComponent
        rows={tableProps.getRowModel().rows}
        columnVirtualizer={columnVirtualizer}
        virtualPaddingLeft={virtualPaddingLeft}
        virtualPaddingRight={virtualPaddingRight}
      />
    </table>
  )
}
