import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import balancesApi from 'api/balances-api.js'
import { REFETCH_INTERVAL } from 'config/constants'

const CEX = 'cexAssets'
const DEX = 'dexAssets'

const parseBalance = (balance) => Number(balance) || 0

function skipZeroFactualBalance(currency) {
  return this.factualBalancesMap[currency]?.factual
}

function sortByBalanceAndName(a, b) {
  if (this.factualBalancesMap[b]?.factual || this.factualBalancesMap[a]?.factual) {
    return this.factualBalancesMap[b]?.factual - this.factualBalancesMap[a]?.factual
  }

  return a > b ? 1 : -1
}

const getBalancePercentage = (expected, factual) =>
  // eslint-disable-next-line eqeqeq
  expected != 0 ? Math.round(((factual - expected) * 100) / expected) : 0

const parseBalances = (balances, expectedBalances) => {
  const expectedBalancesMap = {}
  const factualBalancesMap = {}

  if (expectedBalances) {
    for (const expected of expectedBalances) {
      expectedBalancesMap[expected.name] = {
        expected: parseBalance(expected.expected),
      }
    }
  }

  const tableDataRaw = {
    [CEX]: {},
    [DEX]: {},
  }
  const tableData = []

  const currenciesSet = new Set()

  if (balances?.[CEX]) {
    for (const balance of balances[CEX]) {
      currenciesSet.add(balance.name)

      if (!tableDataRaw[CEX][balance.exchange]) {
        tableDataRaw[CEX][balance.exchange] = {
          values: {},
          wallet: undefined,
        }
      }

      const currencyBalance = factualBalancesMap[balance.name]?.factual || 0
      const newFactual = currencyBalance + parseBalance(balance.balance)
      const expected = expectedBalancesMap[balance.name]?.expected || 0

      factualBalancesMap[balance.name] = {
        factual: newFactual,
        percentage: getBalancePercentage(expected, newFactual),
      }

      tableDataRaw[CEX][balance.exchange].values[balance.name] = {
        balance: balance.balance,
        reserved: balance.reserved,
      }
    }
  }

  if (balances?.[DEX]) {
    for (const balance of balances[DEX]) {
      currenciesSet.add(balance.name)

      if (!tableDataRaw[DEX][balance.network]) {
        tableDataRaw[DEX][balance.network] = {}
      }

      if (!tableDataRaw[DEX][balance.network]?.[balance.dexWallet]) {
        tableDataRaw[DEX][balance.network][balance.dexWallet] = {
          values: {},
          wallet: balance.dexWallet,
        }
      }

      const currencyBalance = factualBalancesMap[balance.name]?.factual || 0
      const newFactual = currencyBalance + parseBalance(balance.balance)
      const expected = expectedBalancesMap[balance.name]?.expected || 0

      factualBalancesMap[balance.name] = {
        factual: newFactual,
        percentage: getBalancePercentage(expected, newFactual),
      }

      tableDataRaw[DEX][balance.network][balance.dexWallet].values[balance.name] = {
        balance: balance.balance,
        reserved: balance.reserved,
      }
    }
  }

  for (const [name, assetValues] of Object.entries(tableDataRaw[CEX])) {
    tableData.push({
      exchangeType: CEX,
      name,
      ...assetValues,
    })
  }

  for (const [name, assetWallets] of Object.entries(tableDataRaw[DEX])) {
    for (const assetValues of Object.values(assetWallets)) {
      tableData.push({
        exchangeType: DEX,
        name,
        ...assetValues,
      })
    }
  }

  tableData.push({
    name: 'Expected',
    values: expectedBalancesMap,
  })

  tableData.push({
    name: 'Factual',
    values: factualBalancesMap,
  })
  const currencies = Array.from(currenciesSet)
    .sort(sortByBalanceAndName.bind({ factualBalancesMap }))
    .filter(skipZeroFactualBalance.bind({ factualBalancesMap }))

  return { tableData, currencies }
}

export const useBalanceData = () => {
  const queryClient = useQueryClient()
  const query = useQuery({
    queryKey: ['balances'],
    queryFn: async () => {
      const [balances, expectedBalances] = await Promise.all([balancesApi.getBalance(), balancesApi.getExpectedBalance()])

      const { tableData, currencies } = parseBalances(balances, expectedBalances)

      return { tableData, currencies, rows: tableData.map(row => ({
        name: row.name,
        exchangeType: row.exchangeType,
        wallet: row.wallet,
        ...row.values,
      })) }
    },
    staleTime: REFETCH_INTERVAL,
    cacheTime: REFETCH_INTERVAL,
    placeholderData: {
      tableData: [],
      currencies: [],
      rows: [],
    },
  })

  const updateFactualCell = useCallback(
    (expected, currency) => {
      queryClient.setQueryData(['balances'], (prevData) => {
        const data = prevData.tableData

        for (const i of Object.keys(data)) {
          if (data[i].name === 'Factual') {
            data[i].values[currency] = {
              ...data[i].values[currency],
              percentage: getBalancePercentage(expected, data[i].values[currency].factual),
            }
          }

          if (data[i].name === 'Expected') {
            data[i].values[currency] = {
              ...data[i].values[currency],
              expected,
            }
          }
        }

        return {
          ...prevData,
          tableData: data,
        }
      })
    },
    [queryClient]
  )

  return [query, updateFactualCell]
}
