import { useQuery, useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'

import { fetch } from './'

const blockedBotsAPI = {
  getBlockedBots: () => {
    return fetch
      .get(`list-blocked-bots`, {})
      .json()
      .catch((err) => console.log(err))
  },

  unblockBot: (botId) => {
    return fetch
      .post(`unblock-bot`, {
        json: {
          bot_id: botId,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },

  blockBot: (botId) => {
    return fetch
      .post(`block-bot`, {
        json: {
          bot_id: botId,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
}

export const useBlockedBots = () => {
  return useQuery({
    queryKey: ['blocked-bots'],
    queryFn: blockedBotsAPI.getBlockedBots,
  })
}

export const useUnblockBot = (id) => {
  return useMutation({
    mutationFn: blockedBotsAPI.unblockBot,
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(['blocked-bots'], (oldData) => {
        return oldData.filter((row) => row.bot_id !== id)
      })
    },
  })
}

export default blockedBotsAPI
