import { FormControl, InputLabel, TextField } from '@mui/material'
import Select from 'react-select'

import s from './BotEditor.module.css'
import { Directions } from './components/Directions'

const steps = [1, 2, 3]

export const BotEditorTripleCex = ({
  values,
  handleChange,
  archived,
  changeAmountState,
  createAmountFields,
  setValues,
  cexExchanges,
}) => {
  return (
    <div className={s.tripleEditorWrapper}>
      <div className={s.tripleBaseParams}>
        <Directions
          values={values}
          archived={archived}
          createAmountFields={createAmountFields}
          changeAmountState={changeAmountState}
          setValues={setValues}
        />
      </div>
      <div className={s.tripleStepParams}>
        {steps.map((step) => (
          <div key={`step-${step}`} className={s.tripleStepParamsRow}>
            <h5 style={{ marginRight: 8 }}>Choose your fighter {step}</h5>
            <FormControl>
              <InputLabel className={s.inputLabel} htmlFor={`step${step}.exchange`}>
                CEX
              </InputLabel>
              <Select
                className={s.inputSelect}
                inputIt={`step${step}.exchange`}
                styles={{
                  control: (base) => ({ ...base, maxWidth: 150, minWidth: 120, marginBottom: 10 }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                options={cexExchanges}
                value={values[`step${step}`]?.exchange}
                getOptionLabel={(x) => x.internal_name}
                getOptionValue={(x) => x.internal_name}
                onChange={(value) => handleChange({ target: { name: `step${step}.exchange`, value } })}
                isDisabled={archived}
                id={`step${step}_exchange_select`}
              />
            </FormControl>

            <FormControl style={{ marginBottom: 8 }}>
              <InputLabel className={s.inputLabel} htmlFor={`step${step}.left`}>
                Left
              </InputLabel>
              <TextField
                className={s.input}
                name={`step${step}.left`}
                value={values[`step${step}`]?.left}
                onChange={handleChange}
                size='small'
                id={`step${step}_left`}
                inputProps={{ min: 0 }}
                disabled={archived}
              />
            </FormControl>

            <FormControl style={{ marginBottom: 8 }}>
              <InputLabel className={s.inputLabel} htmlFor={`step${step}.right`}>
                Right
              </InputLabel>
              <TextField
                className={s.input}
                name={`step${step}.right`}
                value={values[`step${step}`]?.right}
                onChange={handleChange}
                size='small'
                id={`step${step}_right`}
                inputProps={{ min: 0 }}
                disabled={archived}
              />
            </FormControl>
          </div>
        ))}
      </div>
    </div>
  )
}
