import moment from 'moment'
import Table from 'components/Table/Table'
import styled from '@emotion/styled'

const Container = styled(Table)`
  max-height: 400px;
  overflow: auto;
`

const timeFormat = (time, format) => {
  if (time) {
    if (moment.isMoment(time)) {
      return time.local().format(format)
    }

    return moment.utc(time, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').local().format(format)
  }

  return '-'
}

const columns = [
  {
    Header: 'Date',
    accessor: 'requestTime',
    Cell: ({ value }) => timeFormat(value, 'YYYY-MM-DD HH:mm.ss.SSS'),
  },
  {
    Header: 'Kind',
    accessor: 'kind',
  },
  {
    Header: 'Request ID',
    accessor: 'requestId',
  },
  {
    Header: 'Reason',
    accessor: 'errorReason',
  },
]

export const ListSignals = ({ data }) => {
  return (
    <Container
      initialState={{ sortBy: [{ id: 'requestTime', desc: true }] }}
      data={data}
      columns={columns}
    />
  )
}
