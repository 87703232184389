import { useCallback, useState } from 'react'
import moment from 'moment'
import { globalFiltersQuerySelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

import bookkeepingApi from 'api/bookkeeping-api'
// import { mock } from './data'
import get from 'lodash/get'

export const TODAY = 'Today'
export const YESTERDAY = 'Yesterday'
export const LAST_20 = 'Last 20'
export const RANGE = 'Select range'

export const TIME_PLACEHOLDER = '-'

const parseExpenseData = (item) => {
  const parsedItem = { transaction: {} }
  const isCex = /_SPOT$/g.test(item.transaction.requestData.exchangeFrom)
  const [botId] = item.requestId.split('_BL')

  parsedItem.requestId = ''
  parsedItem.groupId = botId + `_ES`
  parsedItem.pair = item.transaction.requestData.cexSymbolLeft
  parsedItem.transaction.requestData = {
    network: isCex ? null : item.transaction.requestData.network,
    exchangeTo: isCex ? item.transaction.requestData.exchangeTo : null,
    exchangeFrom: isCex ? item.transaction.requestData.exchangeFrom : null,
    direction: 'BL',
  }
  parsedItem.dexDuration = TIME_PLACEHOLDER
  parsedItem.cexDuration = TIME_PLACEHOLDER
  parsedItem.totalDuration = TIME_PLACEHOLDER
  parsedItem.transaction.dexRequestTime = TIME_PLACEHOLDER
  parsedItem.transaction.dexResponseTime = TIME_PLACEHOLDER
  parsedItem.transaction.cexResponseTime = TIME_PLACEHOLDER
  parsedItem.transaction.cexRequestTime = TIME_PLACEHOLDER
  parsedItem.transaction.createdAt = item.transaction.createdAt
    ? moment.utc(item.transaction.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000')
    : TIME_PLACEHOLDER
  parsedItem.usdtSpent = 0
  parsedItem.usdtReceived = 0
  parsedItem.profitUsd = item.profitUsd ? +item.profitUsd : 0
  // parsedItem.gas = isCex ? 0 : +expenseItem.amountUsdt
  parsedItem.cexFee = isCex ? +item.cexFee : 0
  parsedItem.networkFeeUsd = isCex ? + 0 : item.transaction.networkFeeUsd
  parsedItem.total = isCex ? 0 - item.cexFee : +item.profitUsd
  parsedItem.profitRaw = {
    total: 0,
  }
  parsedItem.transaction.dexResponse = {
    txHash: item.transaction?.dexResponse?.txHash,
  }

  return parsedItem
}

const parseData = (raw) => {
  const parsedItems = []

  for (const item of raw) {
    if (item.transaction?.requestData?.direction === 'BL') {
      parsedItems.push(parseExpenseData(item))
    } else {
      const parsedItem = { transaction: {}, ...JSON.parse(JSON.stringify(item)) } // deep clone, safe transaction

      if (!item.transaction.dexResponseTime || item.transaction.dexResponseTime === '1970-01-01 00:00:00.000000 +0000 +0000') {
        parsedItem.transaction.dexResponseTime = TIME_PLACEHOLDER
      } else {
        parsedItem.transaction.dexResponseTime = moment.utc(
          item.transaction.dexResponseTime,
          'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'
        )
      }

      if (!item.transaction.dexRequestTime || item.transaction.dexRequestTime === '1970-01-01 00:00:00.000000 +0000 +0000') {
        parsedItem.transaction.dexRequestTime = TIME_PLACEHOLDER
      } else {
        parsedItem.transaction.dexRequestTime = moment.utc(
          item.transaction.dexRequestTime,
          'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'
        )
      }

      if (!item.transaction.cexResponseTime || item.transaction.cexResponseTime === '1970-01-01 00:00:00.000000 +0000 +0000') {
        parsedItem.transaction.cexResponseTime = TIME_PLACEHOLDER
      } else {
        parsedItem.transaction.cexResponseTime = moment.utc(
          item.transaction.cexResponseTime,
          'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'
        )
      }

      if (!item.transaction.cexRequestTime || item.transaction.cexRequestTime === '1970-01-01 00:00:00.000000 +0000 +0000') {
        parsedItem.transaction.cexRequestTime = TIME_PLACEHOLDER
      } else {
        parsedItem.transaction.cexRequestTime = moment.utc(
          item.transaction.cexRequestTime,
          'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'
        )
      }

      if (item.transaction.requestData?.direction) {
        parsedItem.transaction.requestData.direction = item.transaction.requestData.direction.toUpperCase()
      }

      const isClassic = raw.filter(val => val.groupId === item.groupId).length === 1
      const isCex = isClassic || item.transaction.requestData.exchangeFrom === '-'

      parsedItem.usdtSpent = parsedItem.usdtSpent ? +parsedItem.usdtSpent : 0
      parsedItem.usdtReceived = parsedItem.usdtReceived ? +parsedItem.usdtReceived : 0
      parsedItem.baseUsd = parsedItem.baseUsd ? +parsedItem.baseUsd : 0
      parsedItem.profitUsd = parsedItem.profitUsd ? +parsedItem.profitUsd : 0
      parsedItem.networkFeeUsd = parsedItem.networkFeeUsd ? +parsedItem.networkFeeUsd : null
      parsedItem.gas = parsedItem.gas ? +parsedItem.gas : 0
      parsedItem.cexFee = parsedItem.cexFee ? +parsedItem.cexFee : 0
      parsedItem.total = parsedItem.usdtReceived - parsedItem.usdtSpent - parsedItem.gas - parsedItem.cexFee

      if (parsedItem.usdtSpent === 0) {
        parsedItem.profitRaw = {
          pct: null,
          total: parsedItem.total,
          usdtSpent: parsedItem.usdtSpent,
        }
      } else {
        parsedItem.profitRaw = {
          pct: parsedItem.cexFee === '0' ? (parsedItem.total / parsedItem.usdtSpent) * 100 : null,
          total: parsedItem.total,
          usdtSpent: parsedItem.usdtSpent,
        }
      }

      if (parsedItem.transaction.requestData?.dexSymbolLeft && parsedItem.transaction.requestData?.dexSymbolRight) {
        parsedItem.pair =
          parsedItem.transaction.requestData.dexSymbolLeft + ':' + parsedItem.transaction.requestData.dexSymbolRight

        if (/.*-1$/.test(parsedItem.requestId)) {
          parsedItem.pair += '-1'
        }

        if (/.*-2$/.test(parsedItem.requestId)) {
          parsedItem.pair += '-2'
        }
      }

      parsedItem.dexDuration =
        parsedItem.transaction.dexResponseTime !== TIME_PLACEHOLDER &&
          parsedItem.transaction.dexRequestTime !== TIME_PLACEHOLDER
          ? moment.utc(parsedItem.transaction.dexResponseTime.clone().diff(parsedItem.transaction.dexRequestTime.clone()))
            .format('HH:mm:ss.SSS')
          : TIME_PLACEHOLDER

      parsedItem.cexDuration =
        isCex &&
          parsedItem.transaction.cexResponseTime !== TIME_PLACEHOLDER &&
          parsedItem.transaction.cexRequestTime !== TIME_PLACEHOLDER
          ? moment.utc(parsedItem.transaction.cexResponseTime.clone().diff(parsedItem.transaction.cexRequestTime.clone()))
            .format('HH:mm:ss.SSS')
          : TIME_PLACEHOLDER

      parsedItem.totalDuration =
        parsedItem.transaction.cexResponseTime !== TIME_PLACEHOLDER &&
          parsedItem.transaction.dexRequestTime !== TIME_PLACEHOLDER
          ? moment.utc(parsedItem.transaction.cexResponseTime.clone().diff(parsedItem.transaction.dexRequestTime.clone()))
            .format('HH:mm:ss.SSS')
          : TIME_PLACEHOLDER

      if (parsedItem.totalDuration === TIME_PLACEHOLDER) {
        parsedItem.totalDuration =
          parsedItem.cexDuration !== TIME_PLACEHOLDER ? parsedItem.cexDuration : parsedItem.dexDuration
      }

      parsedItem.transaction.createdAt = moment.utc(item.transaction.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000')

      parsedItems.push(parsedItem)
    }
  }

  return parsedItems
}

const filterData = (raw, filter) => {
  if (!filter?.field) {
    return raw
  }

  return raw.filter(bot => {
    return String(get(bot, filter.field)).startsWith(filter.value)
  })
}

export const useTransactionsApi = (availableNetworks = null, filterTransactionsBy = null) => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState([])
  const [profit, setProfit] = useState([])
  const [requestedPeriod, setRequestedPeriod] = useState({})
  const filters = useSelector(globalFiltersQuerySelector)

  const fetch = useCallback(async ({ startTime, endTime, page, pageSize }) => {
    setIsFetching(true)

    try {
      const from = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss')
      const to = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss')

      setRequestedPeriod({ from, to })

      const response = await bookkeepingApi.getBookkeepingByPeriod(
        from,
        to,
        filters
      )

      if (response?.records?.length) {
        const parsedBookkeeping = parseData(response.records)
        const filteredBookkeeping = filterData(parsedBookkeeping, filterTransactionsBy)

        setData([...filteredBookkeeping])
        setProfit(response.profit)
      } else {
        setData([])
        setProfit([])
      }
    } catch (e) {
      console.error(e)
      setData([])
      setProfit([])
    } finally {
      setIsFetching(false)
    }
  }, [filterTransactionsBy, filters])

  const fetchSelectedPeriod = useCallback(
    (period) => {
      return fetch({
        startTime: period.startTime || period.startDate,
        endTime: period.endTime || period.endDate,
      })
    },
    [fetch]
  )

  return [data, profit, isFetching, fetchSelectedPeriod, requestedPeriod]
}
