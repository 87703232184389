import { useMemo } from 'react'
import styled from '@emotion/styled'

import { Chip } from '@mui/material'

const List = styled.div`
  display: flex;
  gap: 6px;
`

const Kind = ({ kind, count, selectedKind, setSelectedKind }) => {
  return (
    <Chip
      label={`${count} ${kind}`}
      variant={selectedKind.has(kind) ? 'filled' : 'outlined'}
      onClick={() => setSelectedKind(kind)}
    />
  )
}

export const Kinds = ({ data, selectedKind, setSelectedKind }) => {
  const total = useMemo(() => {
    return Object.entries(data).reduce((acc, [key, value]) => acc + +value, 0)
  }, [data])

  return (
    <List>
      <Kind kind={'All'} count={total} selectedKind={selectedKind} setSelectedKind={setSelectedKind} />
      {Object.entries(data).map(([kind, count]) => {
        return <Kind kind={kind} count={count} selectedKind={selectedKind} setSelectedKind={setSelectedKind} />
      })}
    </List>
  )
}
