import { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

import { useBotSignalAnalytics, useBotListSignals } from 'api/bot-api'
import { Kinds } from './kinds'
import { ListSignals } from './ListSignals'

const Container = styled.div`
  width: 100%;
  padding: 0 10px;
`

export const SignalAnalytics = ({ id, period }) => {
  const [selectedKind, setSelectedKind] = useState(new Set(['All']))
  const signal = useBotSignalAnalytics(id)
  const list = useBotListSignals(id, [...selectedKind], period)

  const toggleKind = useCallback((kind) => {
    setSelectedKind(prev => {
      const updated = new Set(prev)

      if (kind === 'All') {
        if (updated.has('All')) {
          updated.clear()
        } else {
          updated.clear()
          updated.add('All')
        }

        return updated
      }

      updated.delete('All')

      if (updated.has(kind)) {
        updated.delete(kind)
      } else {
        updated.add(kind)
      }

      return updated
    })
  }, [])

  if (!signal.data?.counts) {
    return null
  }

  return (
    <Container>
      <Typography variant={'h5'}>
        Signal Analytics
      </Typography>
      <Kinds
        data={signal.data.counts}
        selectedKind={selectedKind}
        setSelectedKind={toggleKind}
      />
      <ListSignals
        data={list.data?.signals || []}
      />
    </Container>
  )
}
