import { Paper, Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { RefetchButton } from 'components/RefetchButton'
import Preloader from 'components/Preloader/Preloader'

import s from './ReservesTable.module.css'
import { useBalanceData } from './useBalanceData'
import { LowBalanceWarning } from './LowBalanceWarning'
import { ExpectedBalanceWarning } from './ExpectedBalanceWarning'
import { Table } from './Table'

const ReservesTable = () => {
  const [query, updateFactualCell] = useBalanceData()

  return (
    <Paper className={`${s.paper} flex-column`} elevation={2} id='balances_table'>
      <div className={s.heading}>
        {!isMobile && <Typography variant={'h5'}>Balances <RefetchButton isRefetching={query.isRefetching} refetch={query.refetch} /></Typography>}
      </div>
      <ExpectedBalanceWarning isFetching={query.isFetching} data={query.data.tableData} />
      <LowBalanceWarning isFetching={query.isFetching} data={query.data.tableData} />
      {query.data.rows?.length ? <Table query={query} updateFactualCell={updateFactualCell} /> : <div className={s.preloader}>
        <Preloader />
      </div>}
    </Paper>
  )
}

export default ReservesTable
