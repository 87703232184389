import moment from 'moment'
import { Link } from 'react-router-dom'
import { RemoveCircleOutline } from '@mui/icons-material'
import { Alert, Button } from '@mui/material'
import { useBlockedBots, useUnblockBot } from 'api/blocked-bots-api'

export const BlockedBots = () => {
  const blockedBots = useBlockedBots()
  const unblockBot = useUnblockBot()

  if (!blockedBots.data) {
    return null
  }

  return blockedBots.data.map((bot) => {
    return (
      <Alert
        key={bot.bot_id}
        style={{ width: '100%' }}
        icon={<RemoveCircleOutline fontSize='inherit' />}
        severity='warning'
        action={
          <>
            <span style={{ padding: '5px 15px 0 0' }}>
              {bot.blocked_until !== 0
                ? 'Will be automatically unblocked after ' +
                  moment.duration(bot.blocked_until - moment().unix(), 'seconds').humanize()
                : 'Won’t be automatically unblocked'}
            </span>
            <Button
              color='inherit'
              size='small'
              onClick={() => {
                unblockBot.mutate(bot.bot_id)
              }}
            >
              Unblock now
            </Button>
          </>
        }
      >
        <Link to={`/bot-details/${bot.bot_id}`}>
          <i>Bot {bot.bot_id}</i>
        </Link>{' '}
        is blocked <strong>{bot.reason !== '' ? `due to ${bot.reason}` : ''}</strong>
      </Alert>
    )
  })
}
