import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import { useState, useEffect, useCallback } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'

import { useExchangesApi } from 'pages/Exchanges/hooks'
import { useNetworksApi } from 'pages/Networks/hooks'
import { useBalanceData } from 'pages/Balances/useBalanceData'

import { FilterItem } from './FilterItem'
import { globalFiltersSelector } from 'redux/selectors/globalFilters'
import { filtersActions } from 'redux/reducers/globalFilters-reducer'

const Buttons = styled(ButtonGroup)`
  padding: 10px;
`

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  z-index: 1;
  backdrop-filter: blur(5px);
  transition: all .3s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;

    > div {
      background: #fff;
    }
  }
`

const Toggle = styled(IconButton)`
  z-index: 2;
`

const showCss = css`
  display: block;
`

const hideCss = css`
  display: none !important;
`

const BOT_TYPES = [
  { id: 'CLASSIC', label: 'Classic' },
  { id: 'TRIPLE', label: 'Triple' },
]

const BOT_STATUSES = [
  { id: 'true', label: 'enabled' },
  { id: 'false', label: 'disabled' },
]

export const Filter = () => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [exchanges] = useExchangesApi()
  const [networks] = useNetworksApi()
  const [balances] = useBalanceData(true)
  const filters = useSelector(globalFiltersSelector)
  const [filtersState, setFiltersState] = useState(filters)
  const [committed, setCommitted] = useState(true)

  useEffect(() => {
    setFiltersState(filters)
  }, [filters])

  const toggleFilter = useCallback((name) => {
    setCommitted(false)
    setFiltersState(prev => {
      return ({
        ...prev,
        [name]: {
          ...prev[name],
          filter: name,
          enabled: isEmpty(prev[name]?.value) ? false : !prev[name]?.enabled,
        },
      })
    })
  }, [])

  const setFilter = useCallback((name, value) => {
    setCommitted(false)
    setFiltersState(prev => ({
      ...prev,
      [name]: {
        filter: name,
        enabled: !isEmpty(value),
        value,
      },
    }))
  }, [])

  const commit = useCallback(() => {
    setCommitted(true)
    dispatch(filtersActions.saveFilters(filtersState))
  }, [dispatch, filtersState])

  const cancel = useCallback(() => {
    setCommitted(true)
    setFiltersState(filters)
  }, [filters])

  const reset = useCallback(() => {
    setCommitted(true)
    setFiltersState({})
    dispatch(filtersActions.saveFilters({}))
  }, [dispatch])

  return <>
    <Container className={cx(show ? showCss : hideCss)}>
      <FilterItem
        label="CEX"
        value={filtersState.cexExchanges?.value || []}
        enabled={filtersState.cexExchanges?.enabled}
        toggle={() => toggleFilter('cexExchanges')}
        toggleDisabled={isEmpty(filtersState.cexExchanges?.value)}
        onChange={(value) => setFilter('cexExchanges', value)}
        options={exchanges.data?.filter(v => v.available && !v.is_dex).map(({ internal_name, name }) => ({ id: internal_name, label: name }))}
        multiple
      />
      <FilterItem
        label="DEX"
        value={filtersState.dexExchanges?.value || []}
        enabled={filtersState.dexExchanges?.enabled}
        toggle={() => toggleFilter('dexExchanges')}
        toggleDisabled={isEmpty(filtersState.dexExchanges?.value)}
        onChange={(value) => setFilter('dexExchanges', value)}
        options={exchanges.data?.filter(v => v.available && v.is_dex).map(({ internal_name, name }) => ({ id: internal_name, label: name }))}
        multiple
      />
      <FilterItem
        label="Networks"
        value={filtersState.networks?.value || []}
        enabled={filtersState.networks?.enabled}
        toggle={() => toggleFilter('networks')}
        toggleDisabled={isEmpty(filtersState.networks?.value)}
        onChange={(value) => setFilter('networks', value)}
        options={networks.data?.map(({ name }) => ({ id: name, label: name }))}
        multiple
      />
      <FilterItem
        label="Assets"
        value={filtersState.assets?.value || []}
        enabled={filtersState.assets?.enabled}
        toggle={() => toggleFilter('assets')}
        toggleDisabled={isEmpty(filtersState.assets?.value)}
        onChange={(value) => setFilter('assets', value)}
        options={balances.map(({ name }) => ({ id: name, label: name }))}
        multiple
      />
      <FilterItem
        label="Bot type"
        value={filtersState.botTypes?.value || null}
        enabled={filtersState.botTypes?.enabled}
        toggle={() => toggleFilter('botTypes')}
        toggleDisabled={isEmpty(filtersState.botTypes?.value)}
        onChange={(value) => setFilter('botTypes', value)}
        options={BOT_TYPES}
      />
      <FilterItem
        label="Bot status"
        value={filtersState.enabled?.value || null}
        enabled={filtersState.enabled?.enabled}
        toggle={() => toggleFilter('enabled')}
        toggleDisabled={isEmpty(filtersState.enabled?.value)}
        onChange={(value) => setFilter('enabled', value)}
        options={BOT_STATUSES}
      />
      {!committed && <Buttons variant="contained">
        <Button onClick={commit}>Filter</Button>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={reset}>Reset</Button>
      </Buttons>}
    </Container>
    <Tooltip title={show ? 'hide filters' : 'show filters'}>
      <Toggle onClick={() => setShow(prev => !prev)}>
        <FilterAlt />
      </Toggle>
    </Tooltip>
  </>
}
