import BigNumber from 'bignumber.js'
import { getAddressPage } from 'utilities/get-address-page'
import { ExpectedForm } from './ExpectedForm'
import { formatLongNumbers } from 'utilities/number-helpers'
import s from '../ReservesTable.module.css'
import cx from 'classnames'
import { createColumnHelper } from '@tanstack/react-table'

const columnHelper = createColumnHelper()

export const getColumns = (currencies, updateFactualCell, availableNetworks, filters) => {
  const filteredCurrencies = filters?.assets?.enabled && filters.assets.value.length ? filters.assets.value.map(({ id }) => id) : currencies

  const columns = [
    columnHelper.accessor('name', {
      size: 200,
      cell: props => {
        const { row } = props
        const value = props.getValue()

        return (
          <div className='column text-left' style={{ width: '100%' }}>
            <strong style={{ color: '#fff' }}>{value}</strong>
            <small className='ellipsis' style={{ maxWidth: 180 }}>
              <a href={getAddressPage(availableNetworks, row.original.name, row.original.wallet)} target="_blank" rel="noreferrer">
                {row.original.wallet}
              </a>
            </small>
          </div>
        )
      },
    }),
    ...filteredCurrencies.map(currency => columnHelper.accessor(currency, {
      size: 180,
      cell: (props) => {
        const { row } = props

        if (row.original.name === 'Expected') {
          return (
            <ExpectedForm
              updateFactualCell={updateFactualCell}
              expected={row.original[currency]?.expected}
              currency={currency}
            />
          )
        }

        if (row.original.name === 'Factual') {
          const { factual, percentage } = row.original[currency] || {}
          const value = new BigNumber(factual).toFixed(5)

          return (
            <div className='flex-column'>
              <strong className={s.longNum}>{formatLongNumbers(value)}</strong>
              <small className={percentage > 0 ? 'green' : 'red'}>{percentage}%</small>
            </div>
          )
        }

        const asset = props.getValue()

        if (!asset?.balance && !asset?.reserved) {
          return <strong className='red'>-</strong>
        }

        let balance = new BigNumber(asset.balance).toFixed(5)
        const [start, end] = balance.split('.')

        if (end === '00000') {
          balance = start
        }

        if (balance === '0') {
          if (asset.balance === '0') {
            balance = ''
          } else {
            balance = '0'
          }
        }

        if (Number.isNaN(balance) || balance === 'NaN') {
          balance = ''
        }

        return (
          <div className='flex-column'>
            {balance && <strong className={cx('green', s.longNum)}>{formatLongNumbers(balance)}</strong>}
            {!balance && <strong className='green long-num'>{balance}</strong>}
            <small className='red'>{
            // eslint-disable-next-line eqeqeq
              asset.reserved != 0 ? new BigNumber(asset.reserved).toFixed(5) : 0
            }</small>
          </div>
        )
      },
    })),
  ]

  return columns
}
