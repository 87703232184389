import { useMemo, useRef, useCallback } from 'react'
import { useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table'
import { useNetworksSelector } from 'redux/selectors/settings'

import Preloader from 'components/Preloader/Preloader'

import s from '../ReservesTable.module.css'
import { globalFiltersSelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

import { getColumns } from './columns'
import { ReservesTableBody } from './ReservesTableBody'

import { useVirtualizer, defaultRangeExtractor } from '@tanstack/react-virtual'

const TableWrap = ({
  query,
  tableProps,
}) => {
  const visibleColumns = tableProps.getVisibleLeafColumns()

  const tableContainerRef = useRef(null)

  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: () => 200, //estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 3, //how many columns to render on each side off screen each way (adjust this for performance)
    rangeExtractor: useCallback(
      (range) => {
        const indexes = defaultRangeExtractor(range)

        if (indexes[0] !== 0) {
          indexes.unshift(0)
        }

        return indexes
      },
      []
    ),
  })

  const virtualColumns = columnVirtualizer.getVirtualItems()

  let virtualPaddingLeft

  let virtualPaddingRight

  if (columnVirtualizer && virtualColumns?.length) {
    if (virtualColumns[1]?.index === 1) {
      virtualPaddingLeft = virtualColumns[0]?.start ?? 0
    } else {
      virtualPaddingLeft = virtualColumns[1]?.start ?? 0
    }
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0)
  }

  return (
    <div
      style={{
        width: '100%', //needed for scrollbar to work correctly
        overflow: 'auto', //our scrollable table container
        position: 'relative', //needed for sticky header
      }}
      ref={tableContainerRef}
    >
      <ReservesTableBody
        virtualPaddingLeft={virtualPaddingLeft}
        virtualPaddingRight={virtualPaddingRight}
        columnVirtualizer={columnVirtualizer}
        tableProps={tableProps}
      />
      {query.isFetching && (
        <div className={s.preloader}>
          <Preloader />
        </div>
      )}
    </div>
  )
}

export const Table = ({ query, updateFactualCell }) => {
  const availableNetworks = useNetworksSelector()
  const filters = useSelector(globalFiltersSelector)
  const columns = useMemo(
    () => {
      if (query.data.currencies && updateFactualCell && availableNetworks && filters) {
        return getColumns(query.data.currencies, updateFactualCell, availableNetworks, filters)
      }

      return []
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data.currencies, updateFactualCell, availableNetworks, filters?.assets?.value]
  )

  const tableProps = useReactTable(
    {
      columns,
      data: query.data.rows,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    }
  )

  return <TableWrap query={query} tableProps={tableProps} />
}
