import { Autocomplete, IconButton, TextField } from '@mui/material'
import Circle from '@mui/icons-material/Circle'
import Tooltip from '@mui/material/Tooltip'

export const FilterItem = ({ options = [], toggleDisabled, multiple, enabled, toggle, value, onChange, label }) => {
  return (
    <div>
      <Tooltip title={enabled ? 'Disable filter' : 'Enable filter'}>
        <IconButton onClick={toggleDisabled ? () => {} : toggle} size="small">
          <Circle fontSize="small" color={enabled ? 'success' : ''} />
        </IconButton>
      </Tooltip>
      <Autocomplete
        disablePortal
        filterSelectedOptions
        openOnFocus
        blurOnSelect
        multiple={multiple}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, newValue) => {
          onChange(newValue)
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
        options={options}
        sx={{ width: 200 }}
        size="small"
      />
    </div>
  )
}
